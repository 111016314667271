<template>

<v-col class="mb-5 pb-5">
  <v-row>
    <v-col cols="12" sm="7" md="6" lg="5" class="text-center mx-auto header-section">
      <v-icon class="d-none d-md-flex" style="font-size:6em; margin-bottom:-70px;" color="primary">mdi-ear-hearing</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="primary">mdi-ear-hearing</v-icon>
      <h2 class="mt-3 ml-4 primary--text">Bouche à oreille</h2>
      <span class="font-papyrus d-block" style="margin-top:50px;">
        Participez au recrutement de nouveaux pirates !
      </span>
      <br>
      <small class="font-papyrus d-block" style="">
        Vous trouverez sur cette page quelques images à partager sur les réseaux, ou à imprimer vous-même !
        <br><br>
        Faites marcher le bouche à oreille pour faire connaître la Monnaie Pirate 
        le plus largement possible autour de vous !
      </small>
    </v-col>
    <v-col cols="11" sm="11" md="10" lg="8" class="text-center mx-auto header-section">
      <v-divider class="my-5"></v-divider>
      
      <v-row>
        <template v-for="image in images">
        <v-col :key="image" cols="12" sm="6" md="4">
          <v-img :src="baseUrl() + 'img/share/'+image" @click="openDialogImgZoom(image)" class="img-share"></v-img>
          <v-btn color="grey darken-3" style="border-radius:0" @click="downloadImg(image)" block>
            <v-icon>mdi-download</v-icon> Télécharger
          </v-btn>
        </v-col>
        </template>
      </v-row>

      <v-dialog v-model="showDialogImgZoom"
              max-width="600" content-class="dialog-large-bordered" scrollable 
              :fullscreen="$vuetify.breakpoint.width < 960">
        <v-card>
          <v-toolbar>
            <v-btn color="black" @click="downloadImg(currentImgZoom)"><v-icon>mdi-download</v-icon> Télécharger</v-btn>
            <v-spacer></v-spacer>
            <v-btn icon outlined small class="mr-0" @click="showDialogImgZoom=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-col style="padding:0px; min-height:600px;">
          <v-img cover width="100%" :src="getUrlImg(currentImgZoom)"/>
          </v-col>
        </v-card>
      </v-dialog>
      
      <br><br><br><br><br><br><br><br><br><br><br>
    </v-col>
  </v-row>
</v-col>

</template>

<style >
.img-share{
  cursor:pointer;
  border:2px solid rgb(66, 66, 66);
  border-radius:5px 5px 0 0;
}
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'share',
  components: {  },
  data: () => ({
    images: [
      "visuel-main-black.png",
      "visuel-main-black-qrcode.png",
      "visuel-main-black-slogan1.png",
      "visuel-main-black-slogan2.png",
      "visuel-main-black-slogan-home.png",
      "visuel-prt-white.png",
      "visuel-prt-white-qrcode.png",
      "visuel-prt-friendly.png",
      "visuel-prt-friendly2.png",
      "visuel-prt-friendly3.png",
    ],
    showDialogImgZoom: null,
    currentImgZoom: ""
    
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/share')
  },
  methods: {
    getUrlImg(imgName){ return this.baseUrl() + "data/download-visuel/" + imgName },
    downloadImg(imgName){
      window.open(this.getUrlImg(imgName), '_blank').focus();
    },
    openDialogImgZoom(imgName){
      this.currentImgZoom = imgName
      this.showDialogImgZoom = true
    },
    baseUrl(){ return core.baseUrl() },
  },
  computed: {
    qrhref(){
      return config.domaineUrl + "/register/" + this.$store.state.auth.user.recruitCode //"https://lamonnaiepirate.net"
    }
    
  }
}
</script>
